import {css, unsafeCSS} from 'lit';
import {sbkCoreBreakpointLg, sbkCoreBreakpointMd, sbkCoreBreakpointXl} from '@/tokens/variables.js'

const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);

export const searchInputStyle = css`
    :host {
        /* todo: Actually add styling onDark */
        --search-input__color: var(--_search-input__color, var(--sbkInputFgColorOnLightDefault));
        --search-input__color--disabled: var(--_search-input__color--disabled, var(--sbkInputFgColorOnLightDisabled));
        --search-inputt__color--error: var(--_search-input__color--error, var(--sbkInputFgColorOnLightError));

        --search-input__bg: var(--_search-input__bg, var(--sbkInputBgColorOnLightEnabled));
        --search-input__bg--active: var(--_search-input__bg--active, var(--sbkInputBgColorOnLightActive));
        --search-input__bg--hover: var(--_search-input__bg--hover, var(--sbkInputBgColorOnLightHover));
        --search-input__bg--focus: var(--_search-input__bg--focus, var(--sbkInputBgColorOnLightFocus));
        --search-input__bg--disabled: var(--_search-input__bg--disabled, var(--sbkInputBgColorOnLightDisabled));
        --search-input__bg--error: var(--_search-input__bg--error, var(--sbkSearchBgColorInputFieldOnLightError));

        --search-input__border: var(--_search-input__border, var(--sbkInputBorderOnLightEnabled));
        --search-input__border--active: var(--_search-input__border--active, var(--sbkInputBorderOnLightActive));
        --search-input__border--disabled: var(--_search-input__border--disabled, var(--sbkInputBorderOnLightDisabled));
        --search-input__border--error: var(--_search-input__border--error, var(--sbkInputBorderOnLightError));
        --search-input__border--focus: var(--_search-input__border--focus, var(--sbkInputBorderOnLightFocus));
        --search-input__border--hover: var(--_search-input__border--hover, var(--sbkInputBorderOnLightHover));
        --search-input__border--readonly: var(--_search-input__border--readonly, var(--sbkInputBorderOnLightDisabled));

        --search-flyout__bg: var(--_search__bg, var(--sbkSelectColorBgColorOnLightFlyoutEnabled));
        --search-flyout-item__bg: var(--_search-flyout-item__bg, var(--sbkSearchBgColorFlyoutEnabled));
        --search-flyout-item__bg--active: var(--_search-flyout-item__bg--active, var(--sbkSearchBgColorFlyoutActive));
        --search-flyout__box-shadow: var(--_search-flyout__box-shadow, var(--sbkSelectFlyoutOnLightShadow));
        --search-option__color: var(--_select-option__color, var(--sbkSelectColorFgColorOnLightDefault));
        --search-option__color--active: var(--_select-option__color--active, var(--sbkSelectColorFgColorOnDarkEnabled));
        --search-option__color--disabled: var(--_select-option__color--disabled, var(--sbkSelectColorFgColorOnLightDisabled));
        --search-option__bg--active: var(--_select-option__bg--active, var(--sbkSelectColorBgColorOnLightFlyoutActive));
  }

    .input {
        padding: var(--sbkInput2xs);
        padding-right: calc(var(--sbkSearchInputSpacingSmallScreenInsetRight) + var(--sbkRoundButtonSizingMaxHeightLarge) + var(--sbkSearchInputSpacingSmallScreenGap));
        background-color: var(--search-input__bg);
        border-radius: var(--sbkInputBorderRadiusDefault);
        border: var(--search-input__border);
        color: var(--search-input__color);
        outline: transparent;
        outline-offset: var(--sbkInputBorderGapFocus);
        font: var(--sbkInputSmallTextSm);
        width: 100%;


        &[type="search"]::-webkit-search-decoration,
        &[type="search"]::-webkit-search-cancel-button,
        &[type="search"]::-webkit-search-results-button,
        &[type="search"]::-webkit-search-results-decoration {
            appearance: none;
        }

        @media all and (min-width: ${breakpointLg}) {
            max-height: var(--sbkInput4xl);
            font: var(--sbkInputLargeTextLg);
            padding: var(--sbkInputXs);
            padding-right: calc(var(--sbkSearchInputSpacingLargeScreenInsetRight) + var(--sbkRoundButtonSizingMaxHeightLarge) + var(--sbkSearchInputSpacingLargeScreenGap));
        }

        &:active {
            background-color: var(--search-input__bg--active);
            border: var(--search-input__border--active);
        }

        &:focus {
            background-color: var(--search-input__bg--focus);
            outline: var(--search-input__border--focus);
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover:not(:focus) {
                background-color: var(--search-input__bg--hover);
                border: var(--search-input__border--hover);
            }
        }
    }

    .search-button {
        position: absolute;
        right: var(--sbkSearchInputSpacingLargeScreenInsetRight);
    }

    #listbox {
        background-color: var(--search-flyout__bg);
        border-radius: var(--sbkSelectBorderRadiusDefault);
        display: none;
        left: 0;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 100;
        margin-top: var(--sbkSelectSpacing3xs);
        box-shadow: var(--search-flyout__box-shadow);
        overflow: hidden;
        padding: var(--sbkSelectSpacingXs) 0;

        &.autocomplete--has-dropdown {
            box-shadow: none;

            .autocomplete-item {
                border-top: var(--sbkFlyoutListItemDivider);
            }

            .autocomplete-item:last-of-type {
                border-bottom: var(--sbkFlyoutListItemDivider);
            }
        }

        &.autocomplete--flyout {
            padding: calc(var(--sbkSearchFlyoutSpacingViewportXlGap) - var(--sbkSelectSpacing3xs)) 0 var(--sbkSelectSpacingXs) 0;

            @media all and (max-width: ${breakpointXl}) {
                padding-top: calc(var(--sbkSearchFlyoutSpacingViewportLgGap) - var(--sbkSelectSpacing3xs));
            }
            @media all and (max-width: ${breakpointLg}) {
                padding-top: calc(var(--sbkSearchFlyoutSpacingViewportMdGap) - var(--sbkSelectSpacing3xs));
            }
            @media all and (max-width: ${breakpointMd}) {
                padding-top: calc(var(--sbkSearchFlyoutSpacingViewportSmGap) - var(--sbkSelectSpacing3xs));
            }
        }
    }

    .autocomplete-item {
        cursor: pointer;
        font: var(--sbkSelectTypographyFlyoutSm);
        color: var(--search-option__color);
        padding: var(--sbkSelectListItemSpacingInsetTopBottom) var(--sbkSelectListItemSpacingInsetLeftRight);
        display: flex;
        gap: var(--sbkSearchSpacingInputField3xs);
        background-color: var(--sbkSearchBgColorFlyoutActive);

        sbk-icon {
            padding-right: var(--sbkSelectListItemSpacingIconGap);
        }

        &.autocomplete-item--flyout {
            padding: var(--sbkSelectListItemSpacingInsetTopBottom) 0;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .autocomplete-item:hover {
            background-color: var(--search-option__bg--active);
            color: var(--search-option__color--active);
        }
    }

    .autocomplete-item[aria-selected] {
        background-color: var(--search-option__bg--active);
        color: var(--search-option__color--active);
    }

    .search-container {
        position: relative;
    }

    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: var(--sbkCoreSpacingMd);
    }

    form {
        margin: 0 auto;
        max-width: var(--sbkCoreContentAreaBodyMaxWidth);
    }
`;
